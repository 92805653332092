:root {
  --color: white;
  --border: #141c27;
  --lighter-border: #233043;
  --gray-color: #fffc;
  --gray-background: #c8c8dc1a;
  --gray-dark-background: #161a20;
  --tippy-background: #1d202c;
  --background: #171717;
  --header-button-background: #20242a;
  --dark-background: #14161d;
  --box-background: #1f2128;
  --anchor-color: #8b5cf6;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: var(--color);
  background-color: var(--background);
  flex-direction: column;
  align-items: center;
  display: flex;
}

input {
  color: var(--color);
  background-color: var(--background);
}

body, input, textarea, button {
  font: 18px / 1.25 -apple-system, BlinkMacSystemFont, Segoe UI, Roboto;
}

.shown {
  display: flex !important;
}

.hidden {
  display: none !important;
}

a {
  color: var(--color);
  text-decoration: none;
}

.fill-image {
  object-fit: fill;
  border-radius: 16px;
  width: 100%;
  height: 100%;
}

.tippy-box[data-theme~="core"] {
  background-color: var(--tippy-background);
  color: var(--color);
  border-radius: 8px;
  font-size: 16px;
}

.tippy-box[data-theme~="core"][data-placement^="top"] > .tippy-arrow:before {
  border-top-color: var(--tippy-background);
}

.tippy-box[data-theme~="core"][data-placement^="bottom"] > .tippy-arrow:before {
  border-bottom-color: var(--tippy-background);
}
/*# sourceMappingURL=repo.d1e9a452.css.map */
